<template>
  <div class="login" v-loading="loading">
    <img class="back" src="@/assets/imgs/log/bjd.png" alt="" />
    <div class="content">
      <div class="content_box">
        <img src="@/assets/imgs/log/log.png" alt="" />
        <div class="inedx">
          <input
            type="text"
            v-model="loginForm.account"
            placeholder="请输入手机账号"
          />
          <input
            type="password"
            v-model="loginForm.password"
            placeholder="请输入密码"
          />
          <input
            type="text"
            v-model="loginForm.code"
            placeholder="请输入验证码"
          />
          <div class="code"></div>
          <div class="button">
            <button @click="login">登录</button>
            <a href="#">修改密码</a>
            <a href="#">忘记密码</a>
          </div>
        <a href="#/reg"> <p class="p">还没有帐号<samp>去注册？</samp></p></a>
        </div>
        <img class="pos" src="@/assets/imgs/log/account.png" alt="" />
        <img class="posi" src="@/assets/imgs/log/lock.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import VueCookies from "vue-cookies"; // 设置cookies
export default {
  data() {
    return {
      loginForm: {
        account: "admin",
        password: "123456",
        code: "L79j",
      },
      loading: false,
    };
  },

  methods: {
    ...mapMutations(["changeLogin"]),
    login() {
      if (this.loginForm.account === "" || this.loginForm.password === ""||this.loginForm.code === "")
        return this.toast("账号密码验证码不能为空");
  
      if (this.loginForm.code.length < 4 || this.loginForm.code.length > 4)
        return this.toast("验证码不能大于4位或小于4位");

      this.loading = true;
      this.$ajax
        .login(this.loginForm)
        .then((res) => {
          this.loading = false;
          this.toast(res.msg, res.code == 200);
          if (res.code != 200) return;
          VueCookies.set("tokenid", res.data.token, 2 * 60 * 60 * 1000);
          //
          this.$router.push("/home");
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100vh;
  position: relative;
  .back {
    width: 100%;
    height: 100vh;
  }
  .content {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    .content_box {
      max-width: 620px;
      height: 450px;
      border-radius: 20px;
      border: 1px solid #fff;
      overflow: hidden;
      margin: 0 auto;
      margin-top: 10%;
      background: rgba(255, 255, 255, 0.2);
      text-align: center;
      img {
        margin-top: 40px;
      }
      .inedx {
        width: 365px;
        height: 300px;
        margin: 0 auto;
        margin-top: 50px;
        ::-webkit-input-placeholder {
          color: #fff;
        }
        　　 input:nth-child(1) {
          width: 365px;
          height: 25px;
          background: none;
          outline: none;
          border: none;
          border-bottom: 1px solid #fff;
          font-size: 17px;
          padding-bottom: 5px;
          letter-spacing: 2px;
          color: #fff;
          text-indent: 30px;
        }
        　　 input:nth-child(2) {
          width: 365px;
          height: 25px;
          background: none;
          outline: none;
          border: none;
          border-bottom: 1px solid #fff;
          letter-spacing: 2px;
          margin: 50px 0 0px 0;
          padding-bottom: 5px;
          font-size: 17px;
          color: #fff;
          text-indent: 30px;
        }
        　　 input:nth-child(3) {
          width: 215px;
          height: 25px;
          background: none;
          outline: none;
          border: none;
          border-bottom: 1px solid #fff;
          letter-spacing: 2px;
          font-size: 17px;
          margin: 50px 0 0px 0;
          color: #fff;
          float: left;
          text-indent: 10px;
        }
        .code {
          width: 103px;
          height: 32px;
          background: lemonchiffon;
          margin: 44px 0 40px 0;
          float: right;
          cursor: pointer;
        }
        .button {
          width: 100%;
          height: 40px;
          overflow: hidden;
          button {
            border: 1px solid #fff;
            border-radius: 5px;
            font-size: 17px;
            font-family: unset;
            font-weight: 600;
            color: #fff;
            letter-spacing: 2px;
            background: rgba(255, 255, 255, 0.2);
            float: left;
            padding: 6px 20px;
            outline: none;
          }
          a {
            font-size: 17px;
            font-family: unset;
            font-weight: 600;
            color: #fff;
            line-height: 30px;
            letter-spacing: 2px;
          }
          a:nth-child(2) {
            float: right;
          }
        }
        .p {
          margin-top: 10px;
          font-size: 17px;
          font-family: unset;
          font-weight: 600;
          color: #fff;
          letter-spacing: 2px;
          float: right;
          samp {
            font-size: 17px;
            font-family: unset;
            font-weight: 600;
            color: #fff;
            letter-spacing: 2px;
            cursor: pointer;
          }
          samp:hover{
            color: #ef505e;
          }
        }
      }
      .pos {
        position: relative;
        top: -335px;
        left: -160px;
      }
      .posi {
        position: relative;
        top: -253px;
        left: -173px;
      }
    }
  }
}
</style>
